@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  @apply m-0 p-0 h-full;
}

#root {
  @apply h-full;
}